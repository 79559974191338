import KNN from "ml-knn"
import SVM from "ml-svm"
import perceptron from "perceptron"
import NeuralNetwork from "./NeuralNetwork"
var class1 = []
var class2 = []
var mode = "knn"
var modes = ["knn", "per", "svm", "ann"]
export const fillpix = (event) => {
    var canvas = document.getElementById('canvas2');
    var ctx = canvas.getContext('2d');
    var rec = canvas.getBoundingClientRect();
    var cx = event.clientX;
    var cy = event.clientY;
    var canvasWidth = canvas.width;
    var canvasHeight = canvas.height;
    var x = Math.floor((-1 * (rec.left - cx) / rec.width) * 400);
    var y = 400 - Math.floor(((rec.bottom - cy) / rec.height) * 400)
    console.log(x, y);


    // ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    var id = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
    var pixels = id.data;

    // var x = Math.floor(Math.random() * canvasWidth);
    // var y = Math.floor(Math.random() * canvasHeight);
    console.log(x, y)
    const radioButtons = document.querySelectorAll('input[name="gender"]');

    let selected;
    for (const radioButton of radioButtons) {
        if (radioButton.checked) {
            selected = radioButton.value;
            break;
        }
    }
    var s = 2;
    if (selected == "1") {
        var r = 0
        var g = 0
        var b = 255
        class1.push([x, y])
    }
    else if (selected == "2") {
        class2.push([x, y])
        var r = 255
        var g = 0
        var b = 0
    }
    else {
        var r = 242
        var g = 231
        var b = 191
        var c1 = class2.indexOf([x, y])
        var c2 = class1.indexOf([x, y])
        console.log(c1, c2)
        s = 10

    }
    for (let i = -s; i <= s; i += 1) {
        for (let j = -s; j <= s; j += 1) {
            var off = ((y + i) * id.width + (x + j)) * 4;

            pixels[off] = r;
            pixels[off + 1] = g;
            pixels[off + 2] = b;
            pixels[off + 3] = 255;
        }
    }
    ctx.putImageData(id, 0, 0);
    console.log(class1, class2);
}
export const clear = () => {
    var canvas = document.getElementById('canvas2');
    var canvasWidth = canvas.width;
    var canvasHeight = canvas.height;
    canvas.getContext('2d').clearRect(0, 0, canvasWidth, canvasHeight);
    class1 = [];
    class2 = [];
    var canvas = document.getElementById("canvas2");
    var ctx = canvas.getContext("2d");
    ctx.fillStyle = "rgb(242,231,191)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
}
const kneigh = () => {
    var data = class1.concat(class2)
    var l1 = new Array(class1.length).fill(0)
    var l2 = new Array(class2.length).fill(1)
    console.log(data, class1, class2)
    var labels = l1.concat(l2)
    console.log(labels)
    var kn = new KNN(data, labels, { k: 1 })
    var out = []

    for (let i = 0; i <= 400; i += 1) {
        for (let j = 0; j <= 400; j += 1) {
            out.push([i, j])
        }
    }
    console.log(out)
    out = kn.predict(out)
    console.log(out)
    var canvas = document.getElementById('canvas2');
    var ctx = canvas.getContext('2d');
    var canvasWidth = canvas.width;
    var canvasHeight = canvas.height;
    var id = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
    var pixels = id.data;
    var c = 0

    for (let i = 0; i <= 400; i += 1) {
        for (let j = 0; j <= 400; j += 1) {
            if (out[c] == 0) {
                var r = 173
                var g = 216
                var b = 230
            }
            else {
                var r = 242
                var g = 231
                var b = 191
            }
            var off = (j * id.width + i) * 4;
            pixels[off] = r;
            pixels[off + 1] = g;
            pixels[off + 2] = b;
            pixels[off + 3] = 255;
            c += 1
        }
    }
    for (let variable of class1) {
        for (let i = -2; i <= 2; i += 1) {
            for (let j = -2; j <= 2; j += 1) {
                var off = ((variable[1] + i) * id.width + (variable[0] + j)) * 4;
                var r = 0
                var g = 0
                var b = 255
                pixels[off] = r;
                pixels[off + 1] = g;
                pixels[off + 2] = b;
                pixels[off + 3] = 255;
            }
        }
    }
    for (let variable of class2) {
        for (let i = -2; i <= 2; i += 1) {
            for (let j = -2; j <= 2; j += 1) {
                var off = ((variable[1] + i) * id.width + (variable[0] + j)) * 4;
                var r = 255
                var g = 0
                var b = 0
                pixels[off] = r;
                pixels[off + 1] = g;
                pixels[off + 2] = b;
                pixels[off + 3] = 255;
            }
        }
    }


    ctx.putImageData(id, 0, 0);
}
const svmp = (c = 10, kern = "rbf") => {
    var options = {
        C: 100,
        tol: 10e-4,
        maxPasses: 10,
        maxIterations: 10000,
        kernel: kern,
        kernelOptions: {
            sigma: 0.5
        }
    };
    var data = class1.concat(class2)
    var l1 = new Array(class1.length).fill(-1)
    var l2 = new Array(class2.length).fill(1)
    console.log(data, class1, class2)
    var labels = l1.concat(l2)
    var svm = new SVM(options);
    svm.train(data, labels);
    var out = []
    for (let i = 0; i <= 400; i += 1) {
        for (let j = 0; j <= 400; j += 1) {
            out.push([i, j])
        }
    }
    out = svm.predict(out)
    var canvas = document.getElementById('canvas2');
    var ctx = canvas.getContext('2d');
    var canvasWidth = canvas.width;
    var canvasHeight = canvas.height;
    var id = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
    var pixels = id.data;
    var c = 0

    for (let i = 0; i <= 400; i += 1) {
        for (let j = 0; j <= 400; j += 1) {
            if (out[c] == -1) {
                var r = 173
                var g = 216
                var b = 230
            }
            else {
                var r = 242
                var g = 231
                var b = 191
            }
            var off = (j * id.width + i) * 4;
            pixels[off] = r;
            pixels[off + 1] = g;
            pixels[off + 2] = b;
            pixels[off + 3] = 255;
            c += 1
        }
    }
    for (let variable of class1) {
        for (let i = -2; i <= 2; i += 1) {
            for (let j = -2; j <= 2; j += 1) {
                var off = ((variable[1] + i) * id.width + (variable[0] + j)) * 4;
                var r = 0
                var g = 0
                var b = 255
                pixels[off] = r;
                pixels[off + 1] = g;
                pixels[off + 2] = b;
                pixels[off + 3] = 255;
            }
        }
    }
    for (let variable of class2) {
        for (let i = -2; i <= 2; i += 1) {
            for (let j = -2; j <= 2; j += 1) {
                var off = ((variable[1] + i) * id.width + (variable[0] + j)) * 4;
                var r = 255
                var g = 0
                var b = 0
                pixels[off] = r;
                pixels[off + 1] = g;
                pixels[off + 2] = b;
                pixels[off + 3] = 255;
            }
        }
    }


    ctx.putImageData(id, 0, 0);
}
const perc = (epochs = 10) => {
    var perce = perceptron()
    var data = class1.concat(class2)
    var l1 = new Array(class1.length).fill(0)
    var l2 = new Array(class2.length).fill(1)
    console.log(data, class1, class2)
    var labels = l1.concat(l2)
    for (let c = 0; c < epochs; c += 1) {
        for (let i = 0; i < data.length; i += 1) {
            perce.train(data[i], labels[i])
        }
    }
    var out=[]
    for (let i = 0; i <= 400; i += 1) {
        for (let j = 0; j <= 400; j += 1) {
            out.push([i, j])
        }
    }
    var k=[]
    for(let el of out){
        k.push(perce.perceive(el))
    }
    out = k    
    var canvas = document.getElementById('canvas2');
    var ctx = canvas.getContext('2d');
    var canvasWidth = canvas.width;
    var canvasHeight = canvas.height;
    var id = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
    var pixels = id.data;
    var c = 0
    for (let i = 0; i <= 400; i += 1) {
        for (let j = 0; j <= 400; j += 1) {
            if (out[c] == 0) {
                var r = 173
                var g = 216
                var b = 230
            }
            else {
                var r = 242
                var g = 231
                var b = 191
            }
            var off = (j * id.width + i) * 4;
            pixels[off] = r;
            pixels[off + 1] = g;
            pixels[off + 2] = b;
            pixels[off + 3] = 255;
            c += 1
        }
    }
    for (let variable of class1) {
        for (let i = -2; i <= 2; i += 1) {
            for (let j = -2; j <= 2; j += 1) {
                var off = ((variable[1] + i) * id.width + (variable[0] + j)) * 4;
                var r = 0
                var g = 0
                var b = 255
                pixels[off] = r;
                pixels[off + 1] = g;
                pixels[off + 2] = b;
                pixels[off + 3] = 255;
            }
        }
    }
    for (let variable of class2) {
        for (let i = -2; i <= 2; i += 1) {
            for (let j = -2; j <= 2; j += 1) {
                var off = ((variable[1] + i) * id.width + (variable[0] + j)) * 4;
                var r = 255
                var g = 0
                var b = 0
                pixels[off] = r;
                pixels[off + 1] = g;
                pixels[off + 2] = b;
                pixels[off + 3] = 255;
            }
        }
    }


    ctx.putImageData(id, 0, 0);
}
const ann =(epochs=10) =>{
    var numInputs = 2;
    var numOutputs = 1;
    var numHiddenLayers = 4;
    var numNeuronsPerHiddenLayer = 6; 
    var perce = perceptron()
    var data = class1.concat(class2)
    var l1 = new Array(class1.length).fill(0)
    var l2 = new Array(class2.length).fill(1)
    var labels = l1.concat(l2)
    var neuralNetwork = new NeuralNetwork(numInputs, numOutputs, numHiddenLayers, numNeuronsPerHiddenLayer);
    var labels = l1.concat(l2)
    // for (let c = 0; c < 1; c += 1) {
        for (let i = 0; i < data.length; i += 1) {
            neuralNetwork.update(data[i].concat(labels[i]) )
        }

    // }
    console.log("we here")
    var out=[]
    for (let i = 0; i <= 400; i += 1) {
        for (let j = 0; j <= 400; j += 1) {
            out.push([i, j])
        }
    }
    var k=[]
    for(let el of out){
        k.push(neuralNetwork.update(out.concat([0.5])))
    }
    out = k    
    var canvas = document.getElementById('canvas2');
    var ctx = canvas.getContext('2d');
    var canvasWidth = canvas.width;
    var canvasHeight = canvas.height;
    var id = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
    var pixels = id.data;
    var c = 0
    for (let i = 0; i <= 400; i += 1) {
        for (let j = 0; j <= 400; j += 1) {
            if (out[c] == 0) {
                var r = 173
                var g = 216
                var b = 230
            }
            else {
                var r = 242
                var g = 231
                var b = 191
            }
            var off = (j * id.width + i) * 4;
            pixels[off] = r;
            pixels[off + 1] = g;
            pixels[off + 2] = b;
            pixels[off + 3] = 255;
            c += 1
        }
    }
    for (let variable of class1) {
        for (let i = -2; i <= 2; i += 1) {
            for (let j = -2; j <= 2; j += 1) {
                var off = ((variable[1] + i) * id.width + (variable[0] + j)) * 4;
                var r = 0
                var g = 0
                var b = 255
                pixels[off] = r;
                pixels[off + 1] = g;
                pixels[off + 2] = b;
                pixels[off + 3] = 255;
            }
        }
    }
    for (let variable of class2) {
        for (let i = -2; i <= 2; i += 1) {
            for (let j = -2; j <= 2; j += 1) {
                var off = ((variable[1] + i) * id.width + (variable[0] + j)) * 4;
                var r = 255
                var g = 0
                var b = 0
                pixels[off] = r;
                pixels[off + 1] = g;
                pixels[off + 2] = b;
                pixels[off + 3] = 255;
            }
        }
    }


    ctx.putImageData(id, 0, 0);
}
export const pred = () => {
    console.log(mode)
    if(class1.length!=0 || class1.length!=0){
    if (mode == "knn") {
        kneigh()
    }
    else if (mode == "svm") {
        svmp()
    }
    else if (mode == "per") {
        perc()
    }
    else if (mode == "ann") {
        ann()
    }
}
}
export const chooseModel = (event) => {
    mode = event.target.getAttribute('name')
    var elem = document.getElementById(mode)
    elem.className = "true"
    for (let e of modes) {
        if (e != mode) {
            document.getElementById(e).className = "false"
        }
    }

}
export const forwhat = (event) => {
    var l = ["br-1", "br-2", "br--3"]
    var c = event.target.getAttribute('for');
    document.getElementById(c).checked = "true"
    for (let e of l) {
        if (e != c) {
            document.getElementById(e).checked = "false"
        }
    }

}