import React from 'react';
import './playground.css'
import {fillpix,forwhat,chooseModel,clear,pred} from "./functions"
export default function Playground() {

    return (
        <>
            <div className="playgrounddiv">

                <div id="canvasDiv" >
                    <canvas id="canvas2" width="400" height="400" onClick={(event) => fillpix(event)}>
                    </canvas>
                </div>
                <div id="controls">

                    <div id="brushes">
                        <div>
                            <input name="gender" type="radio" id="br-1" value="1" checked="true" />
                            <label onClick={(event) => forwhat(event)} for="br-1">
                            </label>
                        </div>
                        <div>
                            <input name="gender" type="radio" id="br-2" value="2" />
                            <label onClick={(event) => forwhat(event)} for="br-2"></label>
                        </div>
                        <div>
                            <input name="gender" type="radio" id="br--3" value="-1" />
                            <label for="br--3" onClick={(event) => forwhat(event)}>
                            </label>
                        </div>
                        <button onClick={()=>clear()}id="clearAll">Clear all</button>
                    </div>
                    <div id="model-selector">

                        <div name="knn" onClick={(event) => chooseModel(event)} id="knn" class="true">
                            <img name="knn" class="img" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/knn.png")} />
                            <h4 name="knn">k nearest neighbors</h4>
                        </div>

                        <div name="per" onClick={(event) => chooseModel(event)} id="per" class="false">
                            <img name="per" class="img" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/perceptron.png")} />
                            <h4 name="per"> perceptron </h4>
                        </div>

                        <div name="svm" onClick={(event) => chooseModel(event)} id="svm" class="false">
                            <img name="svm" class="img" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/linear.png")} />
                            <h4 name="svm"> support vector machine</h4>
                        </div>

                        <div name="ann" onClick={(event) => chooseModel(event)} id="ann" class="false">
                            <img name="ann" class="img" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/ann.png")} />
                            <h4 name="ann"> artificial neural network </h4>
                        </div>


                      

                        
                    </div>
                    <span id="buttontry" onClick={()=>pred()} class="link-text" style={{position:"absolute",bottom:"5",textAlign:"center",padding:"0.5em",borderRadius:"1em",backgroundColor:"white"}}>
              Try it!
            </span>
                    {/* <div class="fields">
                    <input type="radio" value="blue" name="gender" defaultChecked/> Class 1
                    <input type="radio" value="red" name="gender" /> Class 2
                    </div>
                    <div class="fields">
                    <button class ='btn'onClick={()=>clear()}>clear</button>
                    <button class ='btn' onClick={()=>pred()}>predict</button>
                    </div> */}
                </div>

            </div>
        </>
    )
}