import React, {useState, useEffect,useRef} from 'react';
import './Homepage.css';
import GLOBE from 'vanta/dist/vanta.globe.min';
// import THREE from 'vanta/vendor/three.r119.min.js';
import * as THREE from 'vanta/vendor/three.r119.min'


export default function Home() {
    const zero = useRef(null);
    const first = useRef(null);
    const second = useRef(null);
    const third = useRef(null);
    const fourth = useRef(null);

    const [vantaEffect, setVantaEffect] = useState(0)
    useEffect(() => {
      if (!vantaEffect) {
        setVantaEffect(GLOBE({
          THREE,
          el: zero.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          color: 0xb05358,
          color2: 0xffffff,
          size: 1.40,
          color: 0xFC766A,
          backgroundColor: 0x101820
        }))
      }
      return () => {
        if (vantaEffect) vantaEffect.destroy()
      }
    }, [vantaEffect])

    return (

    <div className="Page home"   >

        <div id="GreetingCompound" style={{paddingBottom:"100px!important"}} ref={zero}>
          <div id="Greeting">
            <h1 id="GreetingText" >
              we are <br/>
              <span style={{color:"#FC766A"}}>{"<flowsense>"}</span><br/>
            </h1>
            <span id="GreetingSubText">
              making sense of your data with<br/>
              state of the art <span style={{color:"#FC766A"}}>{"<artificial intellence>"}</span> 
            </span>
            <img onClick={() => window.scrollTo({left:0, top:document.body.scrollHeight,behavior: 'smooth'})} class="Arr bouncetop" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/thin-chevron-arrow-bottom-icon.svg")}></img>
          </div>
        </div>

        <div className="ContentCard hvr-grow-shadow" style={{marginBottom:"4%"}} ref={first}>
          <a  className="c-tile c-tile--shape" aria-label="Shape Case Study">
            <h2 className="CTitle" style={{marginTop:"8%"}}>The playing field is poised to become a lot more competitive, and businesses that don’t deploy AI and data to help them innovate in everything they do will be at a disadvantage.</h2>
            <span className="CCategory"><span style={{color:"#FC766A"}}>{"<erik brynjolfsson>"}</span>, director of the MIT initiative on the digital economy</span>          
          </a>
        </div>
    </div>
    )    
}

