import React from 'react';
import './navbar.css'
import { useHistory } from "react-router-dom";
export default function Navbar(props){
    console.log(props)
    const history = useHistory();
    const handleClick = (event) => {
        console.log(event.target.id);
        history.push('/'+event.target.id)
        //window.location.href = '/'+event.target.id;
    }
    return (
        <>
            <div className="Navbar">
                <div className="Nav left">
                <div id="work" className="NavbarText hvr-underline-from-center Work" onClick={(event)=>handleClick(event)}>
                {"<demo>"}
                </div>
                 
             
                <div id="aboutme" className="NavbarText hvr-underline-from-center" onClick={(event)=>handleClick(event)}>
                    {"<about>"}
                </div>



                </div>
                <div className="Nav  spacer">
                    <img className="NavbarBrand iconspin" onClick={()=>history.push('/home')}src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/fuji.svg")}>
                    </img>
                </div>
                <div className="Nav spacing right">
                <div id="offers" className="NavbarText hvr-underline-from-center" onClick={(event)=>handleClick(event)}>
                    {"<offers>"}
                </div>
                <div id="contact" className="NavbarText hvr-underline-from-center" onClick={(event)=>handleClick(event)}>
                    {"<contact>"}
                </div>
                    {/* <span className="jam navbaricon iconspin jam-github"></span>
                    
                    
                    <span className="jam navbaricon iconspin jam-medium"></span>
                    
                    
                    <span className="jam navbaricon iconspin jam-linkedin"></span> */}
                    
                </div>
            </div>
        </>
        )
}