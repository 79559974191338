import React, { useState, useEffect, useRef } from 'react';
import './Contact.css'

import Effect from "../../Components/background/vanta.net"
// import THREE from 'vanta/vendor/three.r119.min.js';
import * as THREE from 'vanta/vendor/three.r119.min'
import * as Sentiment from "sentiment"
import Playground from '../../Components/playground/playground';
import Imageclass from '../../Components/imageclassifier/playground';
export default function Contact() {
  window.THREE = THREE
  const zero = useRef(null);
  const [vantaEffect, setVantaEffect] = useState(0)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(Effect({
        THREE,
        el: zero.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,



      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  const [play, setShowResults] = React.useState(false)
  const onClick = () => setShowResults(true)


  const handleClick = (event) => {
  var newid = event.target.id+"ww";
  console.log(newid)
  var l = ["tw","nw","iw","title"
]
    function mF(item) {
      document.getElementById(item).className += " hideanim";
    }
    function mFR(item) {
      document.getElementById(item).classList.remove("hideanim");
    }
    l.forEach(mF);
    document.getElementById(newid).classList.remove("hide");



    document.getElementById(newid).className += " hideanim2";


    setTimeout(function() {

      document.getElementById(newid).classList.remove("abs");
      window.scrollTo(0, document.body.scrollHeight);
      l.forEach(function(item){document.getElementById(item).className += " hide";
      l.forEach(mFR);


})

  }, 1050);
  setTimeout(function() {

    document.getElementById(newid).classList.remove("hideanim2");

  


}, 2000);
  




  }
  const handleClose = (event) => {
    var newid = event.target.id+"ww";



    document.getElementById(newid).className += " showanim";

    var l = ["tw","nw","iw","title"]
    function mF(item) {
      document.getElementById(item).className += " showanim2";
    }
    function mFR(item) {
      document.getElementById(item).classList.remove("showanim2");
    }
    setTimeout(function() {
      
      document.getElementById(newid).classList.add("hide");
      window.scrollTo(0, document.body.scrollHeight);
      l.forEach(mF);
      l.forEach(function(item){document.getElementById(item).classList.remove("hide");
      document.getElementById(newid).classList.add("abs");
      document.getElementById(newid).classList.remove("showanim");


})

  }, 1050);
  setTimeout(function() {
      
    l.forEach(mFR);

}, 2000);

  }
  var sentiment = new Sentiment()
  const analyze = () => {
    var text = document.getElementById("body").value;
    var result = sentiment.analyze(text);
    console.log(result)
    return result
  }
  return (

    <div className="Page" >
      <div id="GreetingCompound" ref={zero} >
        <div class="WorkDiv">
          <h1 class="WorkTitle" >
            what do we  <br />
            <span style={{ color: "#FC766A" }}> {"<work>"}</span> on?<br />
          </h1>
          <span id="GreetingSubText">
            scroll down to find out <br />
            we offer some <span style={{ color: "#FC766A" }}>{"<examples>"} </span>of our <span style={{ color: "#FC766A" }}>{"<work>"}</span>.
          </span>
          <img onClick={() => window.scrollTo({left:0, top:document.body.scrollHeight,behavior: 'smooth'})} class="Arr bouncetop" src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/thin-chevron-arrow-bottom-icon.svg")}></img>
        </div>
      </div>
      <div id="ll" class="WorkDiv"  style={{ paddingBottom: "0px" }}>
        <h1 id="title" class="WorkTitle" >
          what do you<br />
          want to <span style={{ color: "#FC766A" }}> {"<try>"}</span> out?<br />
        </h1>
        {/* <span id="GreetingSubText3">
          we offer one example <br />
          programm for each <span style={{ color: "#FC766A" }}>{"<data>"} </span>type.
        </span> */}
        <section class="page-contain" id='cwr' style={{ marginTop: "0px",marginBottom: "50px" }}>
          <div id="nw" class="data-card" >
            <h3>numerical</h3>
            <h4>Classification</h4>
            {!play?<p>An implementation of 2 Dimensional data classification using 4 different algorithms.<br/> (K Nearest Neighbors, Perceptron, Support Vector Machine, ANN) <br /></p>:<Playground />}
            <span onClick={(event) => handleClick(event)} id="n" class="link-text" style={{textAlign:"center",padding:"0.5em",borderRadius:"1em",backgroundColor:"white"}}>
              Try it!
            </span>
          </div>
          <div id="iw" class="data-card">
            <h3 >image</h3>
            <h4>Classification</h4>
            <p>Image Classification using a CNN (Convolutional Nerual Network) with multiple Classes including : Bear, Rabbit, Duck, Ant, Beaver.<br/> <br/></p>
            <span onClick={(event) => handleClick(event)} id="i" class="link-text" style={{textAlign:"center",padding:"0.5em",borderRadius:"1em",backgroundColor:"white"}}>
              Try it!
            </span>
          </div>
          <div id="tw" class="data-card">
            <h3>text</h3>
            <h4>SENTIMENT ANALYSIS</h4>
            <p>Simple Sentiment Analyses using a Word Dictionary with added values. Output is a general Sentiment ranging from negative to positive values. </p>
            <span onClick={(event) => handleClick(event)} id="t" class="link-text" style={{textAlign:"center",padding:"0.5em",borderRadius:"1em",backgroundColor:"white"}}>
              Try it!
            </span>
          </div>

        </section>

        {/* <div id='cwr'>
          <div onClick={(event) => handleClick(event)} id="n" class="circ">{"<numerical>"}</div>
          <div onClick={(event) => handleClick(event)} id="i" class="circ">{"<image>"}</div>
          <div onClick={(event) => handleClick(event)} id="t" class="circ">{"<text>"}</div>
        </div> */}
        
      </div>
      <div id="nww" class="ContentCard hide abs" >
      
      <img id="n" onClick={(event)=>handleClose(event)} class="close"src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/icons8-close.svg")}></img>
            <p class="CCategory">NUMERICAL</p>
            <h4 class="CTitle">Classification</h4>
            <Playground />
          </div>

      <div id="iww" class="ContentCard hide abs" >
      
      <img id="i" onClick={(event)=>handleClose(event)} class="close"src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/icons8-close.svg")}></img>

            <p class="CCategory">IMAGE</p>
            <h4 class="CTitle">Classification</h4>
          <Imageclass></Imageclass>
          </div>

      <div id="tww" class="ContentCard hide abs" >
      
      <img id="t" onClick={(event)=>handleClose(event)} class="close"src={require("C:/Users/Admin/Programming/PersonalPage/src/Assets/icons8-close.svg")}></img>

            <p class="CCategory">TEXT</p>
            <h4 class="CTitle">Sentiment Analyses</h4>
            <label for="body">enter a text to analyze</label>
            <textarea rows="8" id="body" name="body" placeholder="">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable of drawing a single stroke at the present moment; and yet I feel that I never was a greater artist than now. When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees, and but a few stray gleams steal into the inner sanctuary, I throw myself down among the tall grass by the trickling stream; and, as I lie close to the earth, a thousand unknown plants are noticed by me: when I hear the buzz of the little world among the stalks, and grow familiar with the countless indescribable forms of the insects and flies, then I feel the presence of the Almighty, who formed us in his own image, and the breath of that universal love which bears and sustains us, as it floats around us in an eternity of bliss;</textarea>
            <input style={{ width: "10%", height: " 8%" }} class="in" type="submit" value="analyze" onClick={() => analyze()} />
          </div>

      
    </div>

  )
}
